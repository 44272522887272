<template>
  <div class="dc-data-table">
    <!-- {{data}} -->
    <el-table
      ref="eltable"
      :size="size"
      :data="data"
      v-loading
      :header-cell-style="setFirstBg"
      element-loading-text
      v-bind="$attrs"
      @filter-change="filterChange"
      @selection-change="selectionChange"
    >
      <slot />
    </el-table>
    <el-pagination
      v-if="noPage"
      ref="pagination"
      class="page-bar"
      background
      layout="prev, pager, next, jumper"
      :page-sizes="pageSizes"
      :page-size="limit"
      :current-page.sync="page"
      @current-change="currentPageChange"
      @size-change="sizeChange"
      @prev-click="prevClick"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
import DataTable from "./dataTable.js";
export default DataTable;
</script>

<style lang="scss">
@import "./dataTable.scss";
</style>