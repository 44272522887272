export default {
    props: {
        dataFlag: {
            // 是否回传数据到父组件
            type: Boolean,
            default: false
        },
        fetch: {
            // 列表 url 的 Promise 对象
            type: Function,
            default: null
        },
        filter: {
            // 查询条件
            type: Object,
            default: () => {
                return {};
            }
        },
        list: {
            // 列表数据, 自定义, 与 fetch 共存时 list 无效
            type: Array,
            default: () => []
        },
        pageSizes: {
            // 页大小配置
            type: Array,
            default: () => [10, 20, 30]
        },
        prevText: {
            // 上一页 文字
            type: String
        },
        nextText: {
            // 下一页 文字
            type: String
        },
        keep: {
            // 是否在 queryString 中保持分页参数
            type: Boolean,
            default: true
        },
        pagesize: {
            type: [Number, String],
            default: 10
        },
        edit: {
            type: Boolean,
            default: false
        },
        //是否要分页
        noPage: {
            type: Boolean,
            default: true
        },
        //表格大小
        size: {
            type: String,
            default: "medium"
        },
        //表头样式
        setFirstBg: {
            type: Object,
            default: ()=>{
                return {background:'#F7F7F7'}
            }
        },

        //分页数据总数
        // total: {
        //   type: [Number, String],
        //   default: 0
        // },
        //分页当前页数
        // page: {
        //   type: [Number, String],
        //   default: 1
        // },
    },
    data() {
        return {
            loading: false,
            data: [],
            total: 1,
            page: 1,
            limit: 10
        };
    },
    computed: {
        criteria() {
            const temp = {};
            Object.keys(this.filter).forEach(key => {
                if (this.filter[key] !== null &&
                    this.filter[key] !== undefined &&
                    this.filter[key] !== '') {
                    temp[key] = this.filter[key]
                }
            })
            return temp
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        reload() {
            this.fetchData();
        },
        fetchData(filter = {}) {
            if (this.fetch !== null) { // fetch 存在时
                this.loading = true;
                // alex, 修改1: 原版没有考虑的情况: 直接在地址栏输入pagesize和page的情况
                this.setParams({});
                this.fetch(
                    Object.assign({
                        page: this.page,
                        limit: this.limit,
                    }, this.keep ? this.$route.query : {}, this.criteria || filter)
                ).then(resp => {
                    this.data = resp.data.data || resp.data.list || resp.data || [];
                    this.total = resp.total
                    this.loading = false;
                    if (this.dataFlag) this.$emit('getTableData', resp.data);
                })
                    .catch(error => {
                        this.loading = false;
                        this.data = [];
                    })
            } else { // 不存在 fetch 时使用传入的 list 数组
                this.data = this.data.concat(this.list)
            }
        },
        setParams(params) {
            if (this.keep) {
                const query = Object.assign({}, this.$route.query || {}, params);

                Object.keys(query).forEach(key => {

                    (['page', 'pagesize'].indexOf(key) != -1) && (+query[key] !== 'NaN') && (query[key] = +query[key]); // 对 page, size 进行处理转成 Number 类型
                    (key === 'page') && (this.page = query.page || 1);
                    // alex, 修改3, 原来是this.pagesize = query.pagesize, 改成这样是为了发送请求的时候, 参数名由pagesize-->limit, 按照接口规范来
                    (key === 'pagesize') && (this.limit = query.pagesize || 10);
                });
                // 改变 url 进行参数保存
                // this.$router.push({
                //   name: this.$route.name,
                //   query
                // });
            }
        },
        prevClick() {
            this.total = this.total - this.data.length - this.limit;
        },
        currentPageChange(page) {
            this.setParams({
                page
            });
            this.fetchData();
            // 父组件捕获
            this.$emit('page-change', page)
        },
        sizeChange(pagesize) {
            this.page = 1;
            this.total = 1;
            this.setParams({
                pagesize
            });
            this.fetchData();
            // 父组件捕获
            this.$emit('page-size-change', pagesize)
        },
        filterChange(filter) {
            Object.keys(filter).forEach(key => {
                (filter[key].length && filter[key].length == 1) && (filter[key] = filter[key][0])
                this.criteria[key] = filter[key]
            })
            this.fetchData(filter);
            // 父组件捕获
            this.$emit('filter-change', filter)
        },
        selectionChange(selection) {
            // 勾选行时触发事件，调用时父组件使用 v-model 绑定一个数组对象即可获取
            this.$emit('input', selection);
        },
        //更改某一行的选中状态
        toggleRowSelection(row) {
            this.$refs.eltable.toggleRowSelection(row);
        },
        //清空所有选中状态
        clearSelection() {
            this.$refs.eltable.clearSelection();
        },
    },
    watch: {
        filter: {
            deep: true,
            handler(val) {
                this.page = 1;
                this.total = 1;
                this.fetchData();
            },
        },
        list: {
            deep: true,
            handler(val) {
                this.data = val
            }
        }
    },

};